<script setup>
const { isLoading, showWrap, text } = useLoading()
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  size: { type: Number, default: 55 },
})
</script>

<template>
  <Transition>
    <div v-show="isLoading && showWrap" class="loading">
      <icon
        icon="mdi:loading"
        class="animate-spin text-sky-600"
        :width="size"
      />
      <span>{{ text }}</span>
    </div>
  </Transition>
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>