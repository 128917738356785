<script setup>
const toasts = useState('toast')
toasts.value = {
  counter: 0,
  list: [],
}

const { $toast } = useNuxtApp()
const prop = defineProps({
  type    : { type: String, default: 'info' },
  position: { type: String, default: 'top-right' },
  showClose: { type: Boolean, default: false },
})

const icon = (type) => {
  switch(type) {
    case 'info'   : return 'mdi:information-variant-circle'
    case 'success': return 'mdi:checkbox-marked-circle'
    case 'warning': return 'mdi:alert'
    case 'error'  : return 'mdi:close-octagon'
  }
}
</script>

<template>
  <div
    class="fixed top-12 right-0 px-2 mt-3 overflow-x-hidden z-50 max-w-xs h-fit"
    :class="{
      'top-12 right-0'   : position == 'top-right',
      'top-12 left-0'    : position == 'top-left',
      'bottom-0 left-0' : position == 'bottom-left',
      'bottom-0 right-0': position == 'bottom-right',
      'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2': position == 'center',
      'top-0 left-1/2 transform -translate-x-1/2 -translate-y-0'    : position == 'top-center',
      'bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-0' : position == 'bottom-center',
      'top-1/2 left-0 transform -translate-x-0 -translate-y-1/2'    : position == 'left-center',
      'top-1/2 right-0 transform -translate-x-0 -translate-y-1/2'   : position == 'right-center',
    }"
  >
    <!-- x-transition:enter="transition ease-in duration-200"
    x-transition:enter-start="transform opacity-0 translate-y-2"
    x-transition:enter-end="transform opacity-100"
    x-transition:leave="transition ease-out duration-500"
    x-transition:leave-start="transform translate-x-0 opacity-100"
    x-transition:leave-end="transform -translate-y-2 opacity-0" -->
    <div
      v-for="(toast, index) in toasts.list"
      v-show="!toast.delete"
      :key="index"
      :class="[
        'flex items-center min-w-[300px] mb-3 px-2 py-3',
        'transition-all ease-in-out duration-1000',
        'bg-gray-900 bg-gradient-to-r text-white rounded shadow-lg',
        {
          'opacity-100' : toast.visible,
          '-translate-y-10 opacity-0' : !toast.visible && position == 'top-right',
          'translate-y-10 opacity-0'  : !toast.visible && position == 'top-left',
          'translate-x-10 opacity-0'  : !toast.visible && position == 'bottom-right',
          '-translate-x-10 opacity-0' : !toast.visible && position == 'bottom-left',
          'from-blue-400 to-blue-500'     : toast.type === 'info',
          'from-green-400 to-green-500'   : toast.type === 'success',
          'from-yellow-400 to-yellow-500' : toast.type === 'warning',
          'from-red-400 to-red-500'       : toast.type === 'error',
        }
      ]
    ">
      <div class="flex items-center w-full">
        <div class="self-start px-1 text-white">
          <Icon :icon="icon(toast.type)" width="25" />
        </div>

        <div v-html="toast.message ? toast.message.replace(/\n/g, '<br>') : ''"></div>

        <template v-if="showClose">
          <div class="flex-1"></div>
          <button
            type="button"
            class="pt-0 px-1 "
            @click="$toast.remove(index)"
          >
            <Icon icon="mdi:close" width="25" />
          </button>
        </template>
      </div>
      <!-- <progress
        :data="progress(toast)"
        :max="100"
        :value="toast.progress"
        class="w-full h-1 p-0"
      ></progress> -->
    </div>
  </div>
</template>